import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';
import { StyledBtn } from '~/components/Generic/Cta/Cta.style';

export const ContactStyled = styled.div`
  padding: 40px;
  margin: 0 auto;
  max-width: 430px;

  & article {
    text-align: center;
    font-size: ${ pxtorem(16) };
    font-weight: 400;

    @media (min-width: 768px) {
      font-size: ${ pxtorem(20) };
    }
  }

  & h1 {
    font-size: ${ pxtorem(40) };
    letter-spacing: -0.01em;
    line-height: 1;
    margin: 0 0 12px;

    & em {
      font-size: ${ pxtorem(34) };
      line-height: 1.18;
    }

    @media (min-width: 768px) {
      font-size: ${ pxtorem(60) };

      & em {
        font-size: ${ pxtorem(55) };
        line-height: 1.09;
      }
    }
  }

  & form {
    margin: 25px auto;
    max-width: 400px;
  }

  & fieldset {
    margin: 0;
    padding: 0;
    border: none;

    & .field {
      margin: 0 0 20px;

      & > label {
        display: block;
        font-size: ${ pxtorem(16) };
        line-height: 1.2;
        color: var(--black);
        margin: 0 0 10px;
        opacity: .3;
      }
    }

    &.actions {
      text-align: center;
    }
  }

  & input[type="text"],
  & input[type="email"],
  & textarea {
    font: inherit;
    font-size: ${ pxtorem(16) };
    line-height: 1.2;
    border: 1px solid rgba(19, 19, 19, 0.1);
    transition: border .25s ease;

    &:focus {
      border: 1px solid rgba(19, 19, 19, 0.5);
      outline: none;
    }
  }

  & input[type="text"],
  & input[type="email"] {
    padding: 18px 30px;
    border-radius: 5rem;

    & + .error {
      text-indent: 30px;
    }

    &::placeholder {
      color: rgba(19, 19, 19, .5);
    }
  }

  & textarea {
    padding: 18px;
    border-radius: 10px;
    resize: none;
  }

  & ${ StyledBtn } {
    margin: 10px auto 0;

    @media (max-width: 500px) {
      width: auto;
    }
  }

  & .error {
    font-size: ${ pxtorem(12) };
    color: #f00;
    padding: .5em 0 0;
  }

  & .field:has(.error) {
    & input[type="text"],
    & input[type="email"] {
      border-color: #f00;
    }
  }
`;
