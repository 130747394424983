import { FC, useState } from 'react';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { ContactStyled } from './contact.styled';
import { StyledBtn } from '~/components/Generic/Cta/Cta.style';
import { useForm } from 'react-hook-form';
import { Any } from '../types/types';

interface FormValues extends Record<string, string> {
  email: string;
  name: string;
  message: string;
}

const Contact: FC<Any> = props => {
  const { handleSubmit, register, reset, errors } = useForm<FormValues>();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = handleSubmit(data => {
    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    formData.append('form-name', 'contact');

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData as URLSearchParams).toString(),
    })
      .then(() => {
        setSubmitted(true);
        reset();

        setTimeout(() => {
          setSubmitted(false);
        }, 2500);
      })
      .catch(error => alert(error));
  });

  return (
    <Layout>
      <Metadata title="Contact" />

      <ContactStyled>
        <article>
          <h1>
            Get in <em>touch</em>
          </h1>

          <p>
            If you have product feedback or want to meet an Ambassador in your
            area - or anything in between - please reach out here. We’ll get
            back to you shortly!
          </p>
        </article>

        <form
          method="post"
          netlify-honeypot="clientid"
          data-netlify="true"
          name="contact"
          onSubmit={onSubmit}
        >
          <input type="hidden" name="clientid" />
          <input type="hidden" name="form-name" value="contact" />

          <fieldset>
            <div className="field">
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Please enter a valid email address.',
                  },
                })}
              />
              {errors && errors['email'] && (
                <div className="error">
                  {errors['email'].type === 'required' &&
                    'Email missing. Please enter your email.'}

                  {errors['email'].type === 'pattern' &&
                    'Please enter a valid email address.'}
                </div>
              )}
            </div>

            <div className="field">
              <input
                type="text"
                name="name"
                placeholder="Name"
                ref={register({
                  required: true,
                  minLength: 1,
                })}
              />
              {errors && errors.name && (
                <div className="error">Please enter your name.</div>
              )}
            </div>

            <div className="field">
              <label>Your message</label>
              <div>
                <textarea
                  name="message"
                  rows={6}
                  cols={40}
                  ref={register({
                    required: true,
                    minLength: 5,
                  })}
                ></textarea>
                {errors && errors.message && (
                  <div className="error">Please enter a message.</div>
                )}
              </div>
            </div>
          </fieldset>

          <fieldset className="actions">
            {submitted ? (
              <div className="thankyou">Thank you</div>
            ) : (
              <StyledBtn className="cta" type="submit">
                <div className="cta__inner">
                  <span className="cta__label">Send</span>
                </div>
              </StyledBtn>
            )}
          </fieldset>
        </form>
      </ContactStyled>
    </Layout>
  );
};

export default Contact;
